function getFormInputField(value, onChangeStateSetter, attributes) {
  let className = "appearance-none rounded-md bg-gray-50 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 sm:text-sm focus:outline-none focus:ring-1 focus:ring-headerFooterBg focus:border-headerFooterBg focus:z-10 shadow-sm";
  if (attributes?.id === "password") className = className.replace(" relative", "");
  return (
      <input
        { ...attributes }
        className={ className }
        required
        value={ value }
        onChange={ event => onChangeStateSetter(event.target.value) }
      />
  )
};

function getPasswordFieldWithLabel(password, setPassword, showPassword, setShowPassword, labelName) {
  return (
    <div>
      <div className="text-left text-gray-500 text-sm font-semibold mb-1">{ labelName ? labelName : "Password" }</div>
      <label htmlFor="password" className="sr-only">{ labelName ? labelName : "Password" }</label>
      <div className="relative">
        { getFormInputField(password, setPassword, {"id": "password", "name": "password", "type": showPassword ? 'text' : 'password'}) }
        <div className="absolute inset-y-0 right-0 flex items-center px-2">
          <button type="button" className="px-2 py-1 text-sm text-gray-500 hover:text-gray-600 cursor-pointer focus:outline-none" 
          onClick={() => setShowPassword(!showPassword)}>
            {showPassword
              ? <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
              : <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
            }
          </button>
        </div>
      </div>
    </div>
  );
}

function getUsernameFieldWithLabel(username, setUsername, includePlaceholder = false) {
  let attributes = {"id": "username", "name": "username", "type": "text"};
  if (includePlaceholder) {
    attributes['placeholder'] = "username123"
  }

  return (
    <div>
      <div className="text-left text-gray-500 text-sm font-semibold mb-1">Username</div>
      <label htmlFor="username" className="sr-only">Username</label>
      { getFormInputField(username, setUsername, attributes) }
    </div>
  )
}

export { getUsernameFieldWithLabel, getPasswordFieldWithLabel };