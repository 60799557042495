import React from 'react';
import HomeHero from './HomeHero'
import HomeOurStory from './HomeOurStory';
import HomeWhenAndWhere from './HomeWhenAndWhere';

const Home = () => {
  return (
    <div>
      <HomeHero /> 
      <HomeOurStory />
      <HomeWhenAndWhere />
    </div>
  )
}

export default Home;