function getMainSubPageHeader(title, subtitle) {
  return (
    <div className="text-center ">
      <div className="font-greatVibes text-5xl xl:text-6xl font-bold pt-6">{title}</div>
      {subtitle &&
        <div className="text-xl font-extralight sm:text-2xl pt-3">
          <div>{subtitle}</div>
        </div>
      }
      <hr className="my-6 border border-primary" />
    </div>
  );
};

export {
  getMainSubPageHeader
};