import React from 'react'
import PropTypes from 'prop-types'

const TimelineItem = ({ data, rhs}) => {
  const lhsCircleStyle = "top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2";
  const rhsCircleStyle = "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"

  return (
    <div className={"relative md:py-4 flex" + (rhs ? " flex-row-reverse" : "")}>
      <div className={"shadow-3xl rounded-lg md:w-p45 "}>
        
        <img className="content-center border-none rounded-t-lg" src={data.img} alt={data.date + " " + data.location}/>

        <div className="bg-white px-4 rounded-b-lg">
          <header className={"text-2xl font-semibold pt-2" + (rhs ? "" : " md:text-right")}>
            {data.date}
          </header>
          
          <div className={"font-extralight italic text-lg" + (rhs ? "" : " md:text-right")}>
            {data.location}
          </div>

          <div className={"pt-1 pb-2 font-light text-base lg:text-lg" + (rhs ? "" : " md:text-right") }>
            {data.description}
          </div>
        </div>
      </div>
      
      <div className={"hidden md:block absolute h-full w-1 bg-gray-600 z-0 " + (rhs ? rhsCircleStyle : lhsCircleStyle)}/>

      <div className={"hidden md:block absolute h-8 w-8 rounded-full border-4 border-gray-700 bg-gray-100 " + (rhs ? rhsCircleStyle : lhsCircleStyle)} />

    </div>
  )
}

TimelineItem.propTypes = {
  data: PropTypes.object.isRequired,
  rhs: PropTypes.bool.isRequired
}

export default TimelineItem
