import React from 'react';
import config from "../../../config.json";

const MailTo = ({ subject = '', body = '', children, ...p }) => {
  let emailAddress = config.email.ADDRESS;
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  
  return (
    <a href={`mailto:${emailAddress}${params}`} target="_blank" rel="noopener noreferrer" {...p}>
      {children}
    </a>
  )
}

export default MailTo
