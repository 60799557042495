import React from 'react';

import { getSectionHeader, getLinkButton } from './HomeUtils';
import { OurStorySummary, HomeOurStoryData } from '../our-story/OurStoryData';

const HomeOurStory = () => {
  return (
    <div className="bg-white pt-12 pb-2 md:pb-12 px-2 md:px-6 lg:px-12">
      { getSectionHeader("Our Story", "/our-story") }

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 text-center justify-items-center">

        <div className="hidden lg:block lg:col-span-1">
        <img className="h-80 xl:h-96 content-center border-none rounded-lg" src={HomeOurStoryData.past.img} alt={HomeOurStoryData.past.caption} />
          <div className="text-sm font-light italic">{HomeOurStoryData.past.caption}</div>
        </div>

        <div className="col-span-1 my-auto">
          <div className="px-4 md:px-0 pb-6 text-left font-light text-lg">{OurStorySummary}</div>
          <div className="hidden md:block text-center md:text-left lg:text-center pt-2">
            { getLinkButton("/our-story", "Learn More") } 
          </div>
        </div>

        <div className="hidden md:block md:col-span-1">
          <img className="h-80 xl:h-96 content-center border-none rounded-lg drop-shadow-lg" src={HomeOurStoryData.present.img} alt={HomeOurStoryData.present.caption} />
          <div className="text-sm font-light italic">{HomeOurStoryData.present.caption}</div>
        </div>
        
      </div>
      
    </div> 
  )
}

export default HomeOurStory
