import React, { useState, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Account } from './contexts/Accounts';
import Login from './components/login/Login';
import Main from './components/main/Main';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Auth.currentSession()
      .then(_session => {
        setIsLoggedIn(true);
      })
      .catch(_error => {
        // do not need to handle the error here because by default we are not logged in
        // however the browser console complains without a catch statement
      });
      setIsLoading(false);
  }, []);

  // https://codewithnico.com/react-wait-axios-to-render/
  if (isLoading) {
    // maybe add a loading graphic here
    return <div className="App" />
  }

  return (
    <BrowserRouter>
      <div className="App">
        <Account setIsLoggedIn={setIsLoggedIn}>
          <Switch>
            {/* <Redirect exact from="/" to="/home"/> */}
            <Route exact path={["/", "/our-story", "/when-and-where", "/registry", "/profile"]} >
              { isLoggedIn ? <Main /> : <Redirect to="/login" /> }
            </Route>
            <Route path ="/login">
              { isLoggedIn ? <Redirect to="/" /> : <Login /> }
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>            
          </Switch>
        </Account>
      </div>
    </BrowserRouter>
  );
}

export default App;
