import React from 'react';

import heroImage from '../../../images/home/hero.jpg'

const HomeHero = () => {
  return (
    <div className="bg-gradient-to-bl from-gray-100 to-gray-300">
      <div className="grid place-items-center grid-cols-1 md:grid-cols-10 xl:grid-cols-4 gap-4 md:gap-0 px-2 md:px-6 lg:px-12 pt-6 pb-6 lg:pb-10">  
        <div className="col-span-1 md:col-span-3 xl:col-span-1 flex flex-col justify-center text-center md:text-left gap-2 md:gap-4">
          <div className="font-extrabold text-5xl md:text-5xl lg:text-6xl xl:text-7xl">We Got Married!</div>
          <div className="justify-center items-center">
            <div className="md:hidden font-bold text-xl md:text-2xl px-4">Friday, 20 May 2022. Chicago, IL</div>
            <div className="hidden md:block font-bold text-xl md:text-2xl xl:text-3xl">Friday, 20 May 2022</div>
            <div className="hidden md:block font-bold text-xl md:text-2xl xl:text-3xl">Chicago, IL</div>
          </div>
          {/* <div className="py-3">
            { getLinkButton("/rsvp", "RSVP") }
          </div> */}
        </div>
        <div className="col-span-1 md:col-span-7 xl:col-span-3">
          <img className="border-none rounded" src={heroImage} alt="ClaireAndGus-HongKong"/>
        </div>
      </div>

      <div className="relative h-7 md:h-10">
        {/* adding top-px as a hack for mobile resolutions - svg was not flush to bottom border */}
        <svg className="absolute top-px w-full h-7 md:h-10" viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon className="fill-current text-white" points="0,100 100,0 100,100" /> 
         </svg>
      </div>
    </div>
  )
}

export default HomeHero
