import React from 'react';
import parse from 'html-react-parser';

import { getMainSubPageHeader } from '../MainUtils';
import TimelineItem from './TimelineItem';
import { OurStoryParagraphs, TimelineData } from './OurStoryData';

const OurStory = () => {

  return (
    <div className="px-4 md:px-6 lg:px-12">
      { getMainSubPageHeader("Our Story", "Highlights from our journey together...") }

      <div className="font-light md:px-10 text-lg">
        {OurStoryParagraphs.map((p, i) => {
          if (i === OurStoryParagraphs.length - 1) {
            return <p key={i}>{parse(p)}</p>
          } else {
            return <div key={i}><p className="pb-4">{parse(p)}</p></div>
          }
        })}
      </div>

      <div className="flex flex-col pt-8 pb-12 md:px-10 space-y-6 md:space-y-0">
        { TimelineData.map((data, index) =>
          <TimelineItem data={data} rhs={index % 2 !== 0} key={index} />
        )}
      </div>

    </div>
  )
}

export default OurStory