import React, { useState, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Auth } from 'aws-amplify';
import ReactGA from 'react-ga4';

import Header from './header/Header';
import Footer from './footer/Footer';
import Home from './home/Home';
import OurStory from './our-story/OurStory';
import WhenAndWhere from './when-and-where/WhenAndWhere';
import Registry from './registry/Registry';
import Profile from './profile/Profile';
import { getUserDetails } from '../../contexts/Accounts';

const Main = () => {
  const [userDetails, setUserDetails] = useState({isSet: false, username: '', users: []});

  // resets the scroll to top of page when you move across routes
  const history = useHistory();
  history.listen((location, action) => {
    window.scrollTo(0, 0)
  })

  const calcPctProfileComplete = (user) => {
    const oneIfSet = (field) => (field != null) ? 1 : 0;
    let completedCount;
    if (user.is_child) {
      completedCount = [
        // commenting out since only plus-ones can change their first_name last_name
        // oneIfSet(user.first_name) || oneIfSet(user.last_name),
        oneIfSet(user.attending_welcome_dinner),
        oneIfSet(user.attending_wedding)
      ];
    } else if (user.is_plus_one) {
      completedCount = [
        oneIfSet(user.first_name),
        oneIfSet(user.last_name),
        oneIfSet(user.email),
        oneIfSet(user.phone_number),
        oneIfSet(user.attending_welcome_dinner),
        oneIfSet(user.attending_wedding)
      ];
    } else {
      completedCount = [
        // commenting out since only plus-ones can change their first_name last_name
        // oneIfSet(user.first_name) || oneIfSet(user.last_name), 
        oneIfSet(user.email),
        oneIfSet(user.phone_number),
        oneIfSet(user.address),
        oneIfSet(user.attending_welcome_dinner),
        oneIfSet(user.attending_wedding)
      ];
    }
    if (user.attending_wedding !== false) {
      completedCount.push(oneIfSet(user.meal_selection));
    }
    return Math.round(100.0 * completedCount.reduce((a, b) => a + b, 0) / completedCount.length);
  }

  const updateUserDetails = async () => {
    let username = userDetails.username;
    if (username === '') {
      const user = await Auth.currentAuthenticatedUser();
      username = user.username;

      // send data to google analytics
      ReactGA.set({ userId: username });
      // ReactGA.send({ hitType: "pageview", page: "/" });
      ReactGA.send("pageview");
    }

    try {
      const users = await getUserDetails();
      const usersWithPctComplete = users.map(u => {return {...u, 'pctComplete': calcPctProfileComplete(u)}});
      setUserDetails({isSet: true, username: username, users: usersWithPctComplete});
    } catch (error) {
      console.error('updateUserDetails:', error);
      throw error;
    }
  }

  useEffect(() => {
    updateUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // useEffect only runs once when the component mounts

  return (
    <div className="relative min-h-screen bg-gray-100 text-primaryTextColor">
      <div className="pb-10">
        <Header userDetails={userDetails}/>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/our-story" component={OurStory} />
          <Route exact path="/when-and-where" component={WhenAndWhere} />
          <Route exact path="/registry" component={Registry} />

          {/* commenting RSVP link out post wedding */}
          {/* the component key triggers it to re-render when you click again on navbar link - this is helpful if you want to refresh the page after submitting form */}
          {/* note - sending RSVP path to the profile page to avoid creating another big form! */}
          {/* <Route exact path="/rsvp" component={() => <Profile userDetails={userDetails} updateUserDetails={updateUserDetails} key={uuidv4()} />} /> */}

          <Route exact path="/profile" component={() => <Profile userDetails={userDetails} updateUserDetails={updateUserDetails} key={uuidv4()} />} />
        </Switch>
      </div>
      <div className="h-6">
        <Footer />
      </div>
    </div>
  )
}

export default Main;