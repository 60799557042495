import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify, Auth } from 'aws-amplify';
import ReactGA from "react-ga4";

import config from "./config.json"
import App from './App';
import './index.css';

ReactGA.initialize(config.googleAnalytics.GA4_MEASUREMENT_ID);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: config.apiGateway.NAME,
        endpoint: config.apiGateway.ENDPOINT,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);