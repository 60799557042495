import { Link } from 'react-router-dom';

function getSectionHeader(title, linkPath) {
  return (
    <div className="text-center pb-5 md:pb-10 ">
      <Link className="font-greatVibes text-5xl xl:text-6xl font-bold hover:text-blue-700" to={linkPath}>{title}</Link>
    </div>
  )
}

function getLinkButton(to, title) {
  return(
    <Link to={to} className="px-6 py-3 rounded-md text-xl font-semibold text-primaryTextColor border-3 border-headerFooterBgLight hover:bg-headerFooterBgLight hover:text-white  hover:border-headerFooterBgLight transition ease-out duration-200">{title}</Link>
  )
}

export { 
  getSectionHeader,
  getLinkButton
}