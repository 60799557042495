import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getOrDefault } from '../../../utils/Utils';

const PostWeddingGuestCard = ({ user }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [attendingWelcomeDinner, setAttendingWelcomeDinner] = useState(null);
  const [attendingWedding, setAttendingWedding] = useState(null);
  const [mealSelection, setMealSelection] = useState(null);

  useEffect(() => {
    let isMounted = true; 
    if (isMounted) {
      setFirstName(getOrDefault(user.first_name));
      setLastName(getOrDefault(user.last_name));
      setEmail(getOrDefault(user.email));
      setPhoneNumber(getOrDefault(user.phone_number));
      setAddress(getOrDefault(user.address));
      setAttendingWelcomeDinner(getOrDefault(user.attending_welcome_dinner, null));
      setAttendingWedding(getOrDefault(user.attending_wedding, null));
      setMealSelection(getOrDefault(user.meal_selection, null));
    }
    return () => isMounted = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getName = () => {
    if (user.is_plus_one) {
      if (user.first_name) {
        return "Guest: " + user.first_name + " " + getOrDefault(user.last_name);
      } else {
        return "Guest";
      }
    } else if (user.is_child) {
      if (user.first_name) {
        // previously we appended with "Child: " but Zill didn't like it.
        return "" + user.first_name + " " + getOrDefault(user.last_name);
      } else {
        return "Child"
      }
    } else {
      return getOrDefault(user.first_name) + " " + getOrDefault(user.last_name);
    }
  }

  const formLabel = (label) => {
    return (
      <div className="text-gray-500 text-sm font-semibold mb-1">{label}</div>
    )
  }

  const getField = (label, value) => {
    return (
      <div>
        { formLabel(label) }
        <div className="appearance-none relative block w-full px-0 py-2 text-gray-900 sm:text-sm">
          {value ? value : '-'}
        </div> 
      </div>
    );
  }

  return (
    <div className="shadow-3xl rounded-lg bg-white">
      <div className="px-4 sm:px-6 py-4">
        <header className="text-3xl font-semibold">
          { getName() }
        </header>

        <hr className="mt-2 border border-gray-100" />

        {!user.is_plus_one && !user.is_child &&
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 md:grid-rows-3 grid-flow-row md:grid-flow-col gap-y-4 md:gap-y-2 gap-x-6 mb-2">
          { getField("Email", email) }
          { getField("Phone number", phoneNumber) }
          { getField("Address", address) }

          { getField("Attended welcome dinner", attendingWelcomeDinner) }
          { getField("Attended wedding", attendingWedding) }
          { getField("Wedding meal selection", mealSelection) }
        </div>}

        {user.is_plus_one && !user.is_child &&
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 md:grid-rows-4 grid-flow-row md:grid-flow-col gap-y-4 md:gap-y-2 gap-x-6 mb-2">
          { getField("First Name", firstName) }
          { getField("Last Name", lastName) }
          { getField("Email", email) }
          { getField("Phone number", phoneNumber) }
          
          { getField("Attended welcome dinner", attendingWelcomeDinner) }
          { getField("Attended wedding", attendingWedding) }
          { getField(" Wedding meal selection", mealSelection) }
        </div>}

        {!user.is_plus_one && user.is_child &&
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 md:grid-rows-3 grid-flow-row md:grid-flow-col gap-y-4 md:gap-y-2 gap-x-6 mb-2">

          { getField("Attended welcome dinner", attendingWelcomeDinner) }
          { getField("Attended wedding", attendingWedding) }
          { getField("Wedding meal selection", mealSelection) }
        </div>}

      </div>
    </div>
  )
}

PostWeddingGuestCard.propTypes = {
  user: PropTypes.object.isRequired,
}

export default PostWeddingGuestCard