import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AccountContext } from '../../contexts/Accounts';
import { getUsernameFieldWithLabel, getPasswordFieldWithLabel } from './LoginUtils';
import { getCopyright } from '../../utils/Utils';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginStatus, setLoginStatus] = useState({isError: false, message: ""});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { signIn } = useContext(AccountContext);
  const history = useHistory();

  const performAuthenticate = () => {
    signIn(username, password)
      .then(_data => {
        history.push('/');
      })
      .catch(err =>  {
        setIsLoading(false);
        setLoginStatus({isError: true, message: err.message});
      });
  };

  useEffect(() => {
    // https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    let isMounted = true; 
    if (isLoading === true) {
      if (isMounted) performAuthenticate();
    }
    return () => isMounted = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmitLoginWithLoadingSpinner = event => {
    event.preventDefault();
    
    // reset the login status so previous error message goes away once you click Sign in again
    setLoginStatus({isError: false, message: ""});

    if (!username || !password) {
      setLoginStatus({isError: true, message: "Please complete all fields."});
    } else {
      setIsLoading(true);
      // useEffect is triggered next to perform authentication
    }
  }

  return (
    /* 
    THIS IS BLOODY ANNOYING THAT IS OVERFLOWS ON MOBILE BROWSER
    https://stackoverflow.com/questions/61308575/tailwind-h-screen-doesn-t-work-properly-on-mobile-devices
    https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    */
    <div className="min-h-screen flex flex-col relative bg-gray-100">
      <main className="flex-auto flex items-center justify-center text-sm text-center text-gray-600 py-16 px-4 sm:px-6 lg:px-8">

        <div className="max-w-md w-full space-y-8">

          <div>
            <h1 className="mt-6 text-center text-5xl font-extrabold text-headerFooterBg font-pattaya">Claire & Gus</h1>
            <h2 className="mt-2 text-center text-4xl font-light text-primaryTextColor">Sign in to your account</h2>
            {loginStatus.isError &&
              <p className="text-xl font-semibold text-redAlert flex items-center justify-center mt-4">
                <svg className="w-6 h-6 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd"></path></svg>
                {loginStatus.message}
              </p>
            }
          </div>

          <form className="mt-8 space-y-6" onSubmit={onSubmitLoginWithLoadingSpinner}>
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                { getUsernameFieldWithLabel(username, setUsername, false) }
              </div>
              <div className="pt-2">
                { getPasswordFieldWithLabel(password, setPassword, showPassword, setShowPassword) }
              </div>
            </div>

            <div>
              <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-base font-medium rounded-md text-white bg-headerFooterBg hover:bg-headerFooterBgLight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-headerFooterBgLight" >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {isLoading
                    // show a spinning circle after sign in is clicked (while processing authentication)
                    ? <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0  0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    // show a lock icon prior to clicking sign in button
                    : <svg className="h-5 w-5 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                    </svg>
                  }
                </span>
                {isLoading ? "Signing in..." : "Sign in"}
              </button>
            </div>
          </form>
        </div>
      </main>
      <footer className="flex-none text-sm text-center py-8 sm:px-6 lg:px-10">
        <div className="text-gray-500">{getCopyright()}</div>
      </footer>
    </div>
  );
};

export default Login;