import React, { createContext } from 'react';
import { API, Auth } from 'aws-amplify';
import config from "../config.json"

const AccountContext = createContext(null);

const postUserRsvp = async (accommodation, attending_rehearsal_dinner, attending_wedding, meal_selection, dietary_restrictions) => {
  const params = {
    mode: 'cors',
    body: {
      accommodation,
      attending_rehearsal_dinner,
      attending_wedding,
      meal_selection,
      dietary_restrictions
    }
  };
  return await API.post(config.apiGateway.NAME, config.apiGateway.RSVP_PATH, params);
};

const postGuestDetails = async (body) => {
  const requiredProperties = [
    'party_id', 
    'guest_id', 
    'first_name', 
    'last_name', 
    'email', 
    'phone_number', 
    'address',
    'attending_welcome_dinner',
    'attending_wedding',
    'meal_selection'
  ];
  const missingProperties = requiredProperties.filter(propertyName => !body.hasOwnProperty(propertyName));
  if (missingProperties.length > 0) {
    return new Promise((_, reject) => {
      reject(`postGuestDetails missing properties: ${missingProperties.join(', ')}`);
    })
  }

  const params = {
    mode: 'cors',
    body: body
  };
  return API.post(config.apiGateway.NAME, config.apiGateway.GUESTS_PATH, params);
}

const getUserDetails = async () => {
  try {
    const params = {
      mode: 'cors'
    };
    const response = await API.get(config.apiGateway.NAME, config.apiGateway.GUESTS_PATH, params);
    if (response.body.records.length > 0) {
      return response.body.records;
    } else {
      throw new Error("/guests API did not return at least 1 record");
    }
  } catch (error) {
    console.error('api guests error: ', error);
  }
}

const Account = props => {
  const setIsLoggedIn = props.setIsLoggedIn;

  const signIn = async (username, password) => {
    return await new Promise((resolve, reject) => {
      Auth.signIn(username, password)
        .then(user => {
          setIsLoggedIn(true);
          resolve(user);
        })
        .catch(error => {
          reject(error)
        });
    })
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setIsLoggedIn(false);
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
 
  return(
    <AccountContext.Provider value={{ signIn, signOut }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext, getUserDetails, postGuestDetails,  postUserRsvp };