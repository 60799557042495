import homeSyracuseParty from '../../../images/home/cgSyracuseParty.jpg';
import homeClaires35bday from '../../../images/home/cgClaires35bday.jpg';
import homeCafeBrauer from '../../../images/home/cafeBrauer.jpg';

import timeline1 from '../../../images/ourStory/1-kat_fall_formal_2007.jpg';
import timeline2 from '../../../images/ourStory/2-nyc.jpg';
import timeline3 from '../../../images/ourStory/3-first_vacation_skiing.jpg';
import timeline4 from '../../../images/ourStory/4-france_skiing.jpg';
import timeline5 from '../../../images/ourStory/5-kayaking_in_the_fjords.jpg';
import timeline6 from '../../../images/ourStory/6-engagement.jpg';
import timeline7 from '../../../images/ourStory/7-arrival_in_hong_kong.jpg';
import timeline8 from '../../../images/ourStory/8-hk_hiking.jpg';

export const HomeOurStoryData = {
  past: {
    caption: "Circa 2006 - Unknown basement, Syracuse University",
    img: homeSyracuseParty,
  },
  present: {
    caption: "Oct. 2021 - Peking Duck Birthday Dinner, Hong Kong",
    img: homeClaires35bday
  },
}

export const HomeWhenAndWhereData = {
  caption: "Café Brauer - Lincoln Park, Chicago, IL",
  img: homeCafeBrauer
}

export const OurStorySummary = "A journey that started in a frat house at Syracuse University with a conversation about our mutual upbringings in Chicago has now spanned a decade and a half and three continents. We are so thrilled to celebrate our past, present, and future at our wedding on Friday, 20 May 2022. “Finally!” is the word that comes to mind, especially after a year's postponement, but we know this is still our beginning—and we'd be honored to share it with you, in the city that brought us together in the first place."

export const OurStoryParagraphs = [
  "Like so many storybook romances, ours started in the sticky living room of the Zau Beta Tau house in Syracuse, N.Y., over warm vodka and Sprite. Gus was there with his friends and Claire was there with hers, including her best friend from high school, Heidi, who was visiting campus.",
  "When Claire introduced Heidi as “from Chicago,” Gus replied, “I'm from Chicago too.” That sparked the inevitable Cubs vs. Sox debate (Cubs for us both, otherwise, would we be here—who knows?) and a conversation about growing up during Michael Jordan's glory days. After hopping to another frat house, we parted ways without exchanging numbers.",
  "But in true 2006 fashion, a Facebook friend request the next morning brought us back together, and we messaged back and forth until the spring semester ended. Our Chicago connection meant we were leaving campus for the same place, and a few weeks later we had our first date: dinner at Tortilla Flats in Oak Park and a showing of the <i>Da Vinci Code</i>. Claire was rightly swept off her feet.",
  "The rest, as they say, is history—a history that spans a decade and half, three continents, and three long-distance stints, twice with an ocean between us.",
  "We are so thrilled to celebrate our past, present, and future at our wedding on Friday, 20 May 2022. “Finally!” is the word that comes to mind, especially after a year's postponement, but we know this is still our beginning—and we'd be honored to share it with you, in the city that brought us together in the first place."
]

export const TimelineData = [
  {
    date: "Fall 2007",
    location: "Syracuse University",
    description: "Claire and Gus met at Syracuse University. Claire studied journalism and Gus studied math. And here in 2007, they attended Claire's sorority fall formal. It snowed, naturally.",
    img: timeline1
  },
  {
    date: "August 2011",
    location: "New York City",
    description: "In 2008, Gus graduated and moved to NYC. Claire moved there in 2009 and for the next six years they explored the city together, developing a special fondness for the East River running paths and—of course—Central Park.",
    img: timeline2
  },
  {
    date: "December 2011",
    location: "Park City, Utah",
    description: "In the winter of 2011, Gus talked Claire into a ski getaway—their first trip together. Claire was a complete novice and Gus was only slightly more advanced, having spent some of his teenage years mastering the gnarly slopes of…Illinois. After many bruises and a few faceplants, they both came to love the sport and have skied together in the U.S., Canada, and France.",
    img: timeline3
  },
  {
    date: "February 2015",
    location: "Val Thorens, French Alps",
    description: "In July 2014, Gus relocated to London for work and Claire remained in New York. Despite the distance, Gus's move gave them an opportunity to start exploring Europe together, such as this 2015 trip to France. A year later, Claire moved to London too.",
    img: timeline4
  },
  {
    date: "May 2016",
    location: "Bergen, Norway",
    description: "During their stint in London, Claire and Gus visited more than a dozen European countries together. They especially loved the food and culture of Italy and Amsterdam, but they formed some of their fondest memories on the backroads of Scotland and the fjords of Norway (where they are here in spring 2016).",
    img: timeline5
  },
  {
    date: "Friday, 27 September 2019",
    location: "London",
    description: "On a rainy Friday evening, Gus proposed to Claire in their kitchen as she made their traditional weekend meal—pizza and margaritas. It was an easy 'yes.'",
    img: timeline6
  },
  {
    date: "February 2020",
    location: "Kowloon Peak, Hong Kong",
    description: "In January 2020, Claire and Gus boarded a flight (together this time!) to Hong Kong to relocate for work. Claire has previously studied abroad in Hong Kong and Gus had been traveling there regularly for work.",
    img: timeline7
  },
  {
    date: "2021",
    location: "Needle Hill, Hong Kong",
    description: "Over the last two years, Claire and Gus have walked many of Hong Kong's (highly underrated) hiking trails. They completed the 100-kilometer MacLehose Trail in early 2021 and take advantage of the nearby route up to Victoria Peak every chance they get.",
    img: timeline8
  },
]