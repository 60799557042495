import React from 'react';

import {getCopyright} from '../../../utils/Utils';

const Footer = () => {
  return (
      <footer className="px-2 md:px-6 lg:px-12 bg-headerFooterBg shadow-md py-2 absolute inset-x-0 bottom-0">
        
        <div className="mx-auto text-center">
          <div className="text-headerFooterBg-unselectedText text-sm py-4">{getCopyright()}</div>
        </div>

      </footer>
  )
}

export default Footer
