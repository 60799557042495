import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { AccountContext } from '../../../contexts/Accounts';
import MailTo from './MailTo';
import config from '../../../config.json';

function MyNavLink({ to, ...rest }) {
  const location = useLocation();
  const isActive = location.pathname === to;

  // font-medium is one step up from normal and font-semibold is one step up from font-medium
  const baseClassName="px-2 py-2 text-md font-medium";
  const activeClassName="text-headerFooterBg-selectedText border-b-2 border-headerFooterBg-selectedText font-semibold";
  const inactiveClassName="transition duration-300 ease-in-out hover:text-headerFooterBg-selectedText hover:border-headerFooterBg-selectedText border-transparent border-b-2 hover:border-current";
  const className = baseClassName + (isActive ? ` ${activeClassName}` : ` ${inactiveClassName}`);

  return <Link to={to} className={className} {...rest} />;
}

const Header = ({ userDetails }) => {
  const CONTACT_US_TAG = "Contact Us";

  const { signOut } = useContext(AccountContext);
  const [ showMobileMenu, setShowMobileMenu ] = useState(false);
  const [ showUserMenu, setShowUserMenu ] = useState(false);

  const [ showNotificationsMenu, setShowNotificationsMenu ] = useState(false);
  // commenting out and switching from state to const since we no longer have any notifications
  // const [ numberOfNotifications, setNumberOfNotifications ] = useState(0);
  const numberOfNotifications = 0;
  // const [ notifications, setNotifications] = useState({
  //   "UPDATE_PROFILE": false,
  //   "RSVP": false
  // });
  const notifications = { "UPDATE_PROFILE": false, "RSVP": false };

  const [ emailSubject, setEmailSubject ] = useState(`[Claire & Gus] ${CONTACT_US_TAG}`)

  useEffect(() => {
    if (userDetails.isSet) {
      // turning off UPDATE_PROFILE since after the wedding we are no longer allowing users to post updates to their profile
      // it is also not technically possible since we are moving from a database to static guest list file
      // if (userDetails.users.some(u => u.pctComplete < 100)) {
      //   setNotifications(n => ({...n, "UPDATE_PROFILE": true}));
      // } else {
      //   setNotifications(n => ({...n, "UPDATE_PROFILE": false}));
      // }
      setEmailSubject(`[Claire & Gus] ${CONTACT_US_TAG} - username: ${userDetails.username}`);
    } else {
      // need to clear notifications so that after the user updates their profile we dont keep counting up
      // notifications and then the badge doesn't go away
    }
  }, [userDetails])

  // useEffect(() => {
  //   setNumberOfNotifications(Object.values(notifications).filter(n => n === true).length)
  // }, [notifications])

  const getNotificationDropdownHtml = () => {
    const weddingDate = new Date(config.data.WEDDING_DATE);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const daysToGo = Math.round((weddingDate - today) / (1000 * 60 * 60 * 24));
    let msg = null;
    if (daysToGo === 0) {
      msg = "Todays the big day!";
    } else if (daysToGo > 0) {
      msg = `${daysToGo} days to go!`
    }

    return (
      <div className="origin-top-right absolute right-0 mt-2 w-44 rounded-md shadow-lg py-1 bg-gray-50 ring-1 ring-black ring-opacity-5 focus:outline-none border border-gray-300 text-gray-700" role="menu" aria-orientation="vertical" aria-labelledby="notification-menu-button" tabIndex="-1">
        <div className="">
          {msg && <div className="block px-4 py-2 text-sm">{msg}</div>}
          {notifications.UPDATE_PROFILE &&
            <Link to="/profile" className="block px-4 py-2 text-sm hover:text-gray-800 hover:font-semibold" role="menuitem" tabIndex="-1" id="user-menu-item-0" onClick={() => setShowNotificationsMenu(false)}>Update Your Profile</Link>
          }
        </div>
      </div>
    )
  }

  const history = useHistory();

  const logoutAndRedirect = async () => {
    await signOut();
    history.push('/login');
  };

  // close user menu (ie the popup when you click on user icon on rhs) when you click away
  // https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
  const userMenuReference = useRef(null);
  const handleClickOutsideUserMenu = (event) => {
    if (userMenuReference.current && userMenuReference.current.contains(event.target)) {
      return;
    }
    setShowUserMenu(false);
  };
  useEffect(() => {
      document.addEventListener('click', handleClickOutsideUserMenu, true);
      return () => {
          document.removeEventListener('click', handleClickOutsideUserMenu, true);
      };
  }, [userMenuReference]);

  // close notifications window on click away
  const notificationMenuReference = useRef(null);
  const handleClickOutsideNotificationMenu = (event) => {
    if (notificationMenuReference.current && notificationMenuReference.current.contains(event.target)) {
      return;
    }
    setShowNotificationsMenu(false);
  };
  useEffect(() => {
      document.addEventListener('click', handleClickOutsideNotificationMenu, true);
      return () => {
          document.removeEventListener('click', handleClickOutsideNotificationMenu, true);
      };
  }, [notificationMenuReference]);

  // close mobile menu (ie the menu that is opened by the hamburger icon) when you click away
  const mobileMenuReference = useRef(null);
  const mobileMenuIconReference = useRef(null);
  const handleClickOutsideMobileMenu = (event) => {
    // mobileMenuIconReference is special handling so that in addition to clicking away closing the mobile menu
    // you can still close it via the X icon when one (since that is technically not part of the mobile menu reference)
    if (mobileMenuIconReference.current && mobileMenuIconReference.current.contains(event.target)) {
      return;
    }
    if (mobileMenuReference.current && mobileMenuReference.current.contains(event.target)) {
      return;
    }
    setShowMobileMenu(false);
  };
  useEffect(() => {
      document.addEventListener('click', handleClickOutsideMobileMenu, true);
      // returning a function is clean up method
      return () => {
          document.removeEventListener('click', handleClickOutsideMobileMenu, true);
      };
  }, [mobileMenuReference]);

  const mobileMenuLink = (args) => {
    const logout = ('logout' in args) ? args.logout : false;
    const alert = ('alert' in args) ? args.alert : false;
    const className="text-headerFooterBg-unselectedText hover:bg-headerFooterBgLight hover:text-headerFooterBg-selectedText block px-3 py-2 rounded-md text-base font-medium"
    if (args.title === CONTACT_US_TAG) {
      return (
        <MailTo subject={emailSubject} className={className} role="menuitem" tabIndex="-1" id="user-menu-item-1" onClick={() => setShowMobileMenu(false)}>
          {CONTACT_US_TAG}
        </MailTo>
      )
    } else {
      return (
        <Link className={`flex ${className}`} to={args.linkPath} onClick={() => logout ? logoutAndRedirect() : setShowMobileMenu(false)}>
          {args.title}
          {alert && <div className="ml-1 w-3 h-3 bg-red-600 rounded-full"></div>}
        </Link>
      )
    }
  }

  return (
    <nav className="bg-headerFooterBg shadow-md sticky top-0 z-50">
      <div className="mx-auto px-2 md:px-6 lg:px-12">
        <div className="relative flex items-center justify-between h-16 text-headerFooterBg-unselectedText">

          {/* mobile menu buttons */}
          <div className="absolute inset-y-0 left-0 flex items-center md:hidden" ref={mobileMenuIconReference}>
            <button type="button" className="inline-flex items-center justify-center p-2 rounded-md hover:text-white hover:bg-headerFooterBgLight focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false" onClick={() => setShowMobileMenu(wasOpened => !wasOpened)} >
              <span className="sr-only">Open main menu</span>
              {showMobileMenu 
                ? <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                : <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" /></svg>
              }
            </button>
          </div>

          {/* primary nav */}
          <div className="flex-1 flex items-center justify-center md:items-stretch md:justify-start">
            <Link to="/" className="flex-shrink-0 flex items-center" onClick={() => setShowMobileMenu(false)}>
              <div className="text-4xl font-bold text-white font-pattaya">Claire & Gus</div>
            </Link>
            <div className="hidden md:block md:ml-6">
              <div className="flex space-x-4 items-center text-center">
                <MyNavLink to="/our-story">Our Story</MyNavLink>
                <MyNavLink to="/when-and-where">When & Where</MyNavLink>
                <MyNavLink to="/registry">Registry</MyNavLink>
                {/* commenting out RSVP page now that wedding is over */}
                {/* <MyNavLink to="/rsvp">RSVP</MyNavLink> */}
              </div>
            </div>
          </div>

          {/* secondary nav */}
          <div className="hidden md:flex absolute inset-y-0 right-0 items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
            {/* notifications - alarm icon with drop down */}
            <div className="relative" ref={notificationMenuReference}>
              <button className="p-1 rounded-full transition duration-300 ease-in-out hover:text-white hover:border-white border-transparent border-2 hover:border-current" id="notifications-menu-button" aria-expanded="false" aria-haspopup="true" onClick={() => setShowNotificationsMenu(wasOpened => !wasOpened)}>
                <span className="sr-only">View notifications</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
                {numberOfNotifications > 0 &&
                <span className="absolute -top-1.5 -right-1.5 w-5 h-5 flex items-center justify-center rounded-full bg-red-600 text-xs font-medium leading-none text-white">{numberOfNotifications}</span>
                }
              </button>
              {showNotificationsMenu && numberOfNotifications > 0 && getNotificationDropdownHtml() }
            </div>

            {/* user - person icon with drop down */}
            <div className="ml-3 relative" ref={userMenuReference}>
              <button type="button" className="p-1 rounded-full transition duration-300 ease-in-out hover:text-white hover:border-white border-transparent border-2 hover:border-current" id="user-menu-button" aria-expanded="false" aria-haspopup="true" onClick={() => setShowUserMenu(wasOpened => !wasOpened)}>
                <span className="sr-only">Open user menu</span>
                <svg className="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </button>
              {showUserMenu &&
                <div className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg py-1 bg-gray-50 ring-1 ring-black ring-opacity-5 focus:outline-none border border-gray-300 text-gray-700 text-sm" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                  <Link to="/profile" className="block px-4 py-2 hover:text-gray-800 hover:font-semibold" role="menuitem" tabIndex="-1" id="user-menu-item-0" onClick={() => setShowUserMenu(false)}>Your Profile</Link>
                  
                  {/* turning off now that we deleted aws WorkMail account */}
                  {/* <MailTo subject={emailSubject} className="block px-4 py-2 hover:text-gray-800 hover:font-semibold" role="menuitem" tabIndex="-1" id="user-menu-item-1" onClick={() => setShowUserMenu(false)}>
                    {CONTACT_US_TAG}
                  </MailTo> */}
                  <div className="flex">
                    <button className="w-full text-left px-4 py-2 hover:text-gray-800 hover:font-semibold" role="menuitem" tabIndex="-1" id="user-menu-item-2" onClick={logoutAndRedirect}>Sign out</button>
                  </div>
                </div>
              }
            </div>
          </div>
        
        </div>
      </div>
    
      {/* Mobile menu, show/hide based on menu state */}
      <div className="md:hidden" id="mobile-menu" ref={mobileMenuReference}>
        {showMobileMenu &&
          <div className="px-2 pt-2 pb-3 space-y-1" >
            { mobileMenuLink({ title: "Our Story", linkPath: "/our-story" }) }
            { mobileMenuLink({ title: "When & Where", linkPath: "/when-and-where" }) }
            { mobileMenuLink({ title: "Registry", linkPath: "/registry" }) }
            {/* commenting out RSVP page now that wedding is over */}
            {/* { mobileMenuLink({ title: "RSVP", linkPath: "/rsvp", alert: notifications.RSVP }) } */}
            { mobileMenuLink({ title: "Your Profile", linkPath: "/profile", alert: notifications.UPDATE_PROFILE }) }
            {/* turning off now that we deleted aws WorkMail account */}
            {/* { mobileMenuLink({ title: CONTACT_US_TAG }) } */}
            { mobileMenuLink({ title: "Sign out", linkPath: "/login", logout: true }) }
          </div>
        }
      </div>
      
    </nav>
  )
}

Header.propTypes = {
  userDetails: PropTypes.object.isRequired
}

export default Header;
