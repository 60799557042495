import React from 'react'

import { getSectionHeader } from './HomeUtils';
import { HomeWhenAndWhereData } from '../our-story/OurStoryData';
import { HomesliceGoogleMapsLinkAndAddress, CafeBrauerGoogleMapsLinkAndAddress } from '../when-and-where/WhenAndWhereData';

const DayList = ({day, items}) => {
  const listItems = items.map((element, i) => <li key={i} className={i===0 ? "font-medium text-xl" : ""}>{element}</li>);
  return (
    <div>
      <div className="font-extralight text-3xl pb-1">{day}</div>
      <ul className="font-light text-lg">
        {listItems}
      </ul>
    </div>
  );
}

const HomeWhenAndWhere = () => {
  return (
    <div className="bg-gradient-to-br from-gray-300 to-gray-100">
      <div className="relative h-7 md:h-10">
        {/* adding -top-px as a hack for mobile resolutions - svg was not flush to bottom border */}
        <svg className="absolute -top-px w-full h-7 md:h-10 text-white" viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon className="fill-current text-white" points="0,0 100,0 0,100" /> 
         </svg>
      </div>
    
      <div className="py-12 px-2 md:px-6 lg:px-12">

      { getSectionHeader("When & Where", "/when-and-where") }

        <div className="grid col-span-1 md:grid-cols-2 lg:grid-cols-6 place-content-center text-center justify-items-center">
          <div className="hidden lg:block col-span-1"></div>
          <div className="col-span-1 lg:col-span-2 flex flex-col gap-4 md:gap-0 justify-between lg:justify-evenly text-left">
            <DayList day="Thursday, 19 May 2022" items={[
              "Welcome Party", 
              <HomesliceGoogleMapsLinkAndAddress />, 
              "7pm - 10pm",
              "Everyone is invited!"]} />
            <DayList day="Friday, 20 May 2022" items={[
              "Wedding Ceremony and Reception",  
              <CafeBrauerGoogleMapsLinkAndAddress />,
              "5pm - Late"
              ]} />
          </div>
          <div className="hidden md:block col-span-1 lg:col-span-2">
            <img className="h-80 xl:h-96 content-center border-none rounded-lg drop-shadow-lg" src={HomeWhenAndWhereData.img} alt={HomeWhenAndWhereData.caption} />
          <div className="text-sm font-light italic">{HomeWhenAndWhereData.caption}</div>
          </div>
          <div className="hidden lg:block col-span-1"></div>
        </div>
      </div>
    </div>
  )
}

export default HomeWhenAndWhere
