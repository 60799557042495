import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import PostWeddingGuestCard from './PostWeddingGuestCard';
import { getMainSubPageHeader } from '../MainUtils';

const Profile = ({ userDetails, updateUserDetails }) => {
  // commenting out since we are switching from GuestCard to PostWeddingGuestCard
  // const [shouldUpdateUser, setShouldUpdateUser] = useState(false);
  const shouldUpdateUser = false;

  // cleanup after the component unmounts
  useEffect(() => {
    return () => {
      if (shouldUpdateUser) {
        updateUserDetails();
      }
    }
  });

  return (
    <div className="px-4 md:px-6 lg:px-12">

      {getMainSubPageHeader("Profile", userDetails.isSet ? `${userDetails.username} - party of ${userDetails.users.length}` : "")}

      {userDetails.isSet && 
        <div className="flex flex-col py-2 md:py-2 md:px-12 space-y-4 md:space-y-6 mb-6">
          {/* {userDetails.users.map((user, index) => <GuestCard user={user} setShouldUpdateUser={setShouldUpdateUser} key={index} />)} */}
          {userDetails.users.map((user, index) => <PostWeddingGuestCard user={user} key={index}/>)}
      </div>}

    </div>
  )
}

Profile.propTypes = {
  userDetails: PropTypes.object.isRequired,
  updateUserDetails: PropTypes.func.isRequired
}

export default Profile;
