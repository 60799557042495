const MyAnchorElement = ({url, urlName}) => {
  return (
    <a 
      href={url} 
      target="_blank" 
      rel="noopener noreferrer"
      className="hyperlinkStyle font-medium">
    {urlName}
    </a>
  )
}

const WebpageAndAddress = ({url, urlName, address}) => {
  return (
    <>
      <MyAnchorElement url={url} urlName={urlName} />
      <p>{address}</p>
    </>
  )
}

export const HotelLincolnWebpageAndAddress = () => {
  return (
    <WebpageAndAddress
      url="https://www.jdvhotels.com/hotels/illinois/chicago/hotel-lincoln"
      urlName="Hotel Lincoln"
      address="1816 N. Clark Street, Chicago, IL 60614" 
    />
  )
}

export const HotelLincolnBookingLink = ({urlName}) => {
  return (
    <MyAnchorElement 
      url="https://www.hyatt.com/en-US/group-booking/CHIJL/G-A5XI" 
      urlName={urlName}
    />
  )
}

export const SonestaESSuitesWebsiteAndAddress = () => {
  return (
    <WebpageAndAddress
      url="https://www.sonesta.com/us/illinois/chicago/sonesta-es-suites-chicago-downtown-magnificent-mile-medical"
      urlName="Sonesta ES Suites"
      address="201 East Walton Place, Chicago, IL 60611" 
    />
  )
}

export const ThompsonChicagoWebsiteAndAddress = () => {
  return (
    <WebpageAndAddress
      url="https://www.thompsonhotels.com/hotels/illinois/chicago/thompson-chicago"
      urlName="Thompson Chicago"
      address="21 East Bellevue Place, Chicago, IL 60611" 
    />
  )
}

export const WestinWebsiteAndAddress = () => {
  return (
    <WebpageAndAddress
      url="https://www.marriott.com/hotels/travel/chiwi-the-westin-michigan-avenue-chicago/"
      urlName="The Westin Michigan Ave"
      address="909 Michigan Avenue, Chicago, IL 60611" 
    />
  )
}

export const CafeBrauerGoogleMapsLinkAndAddress = () => {
  // https://developers.google.com/maps/documentation/urls/get-started
  // const googleMapsUrl = "https://www.google.com/maps/search/?api=1&query=41.91928400715277%2C-87.6338891386505&query_place_id=ChIJraIEwWvTD4gRcaJX-nsB2sE";
  const url = "https://www.google.com/maps/search/?api=1&query=Caf%C3%A9%20Brauer%2C%20North%20Stockton%20Drive%2C%20Chicago%2C%20IL";
  return (
    <WebpageAndAddress
      url={url}
      urlName="Café Brauer"
      address="2021 N. Stockton Drive, Chicago, IL 60614"
    />
  )
}

export const HomesliceGoogleMapsLinkAndAddress = () => {
  const url = "https://www.google.com/maps/search/?api=1&query=Homeslice%2C%20West%20Webster%20Avenue%2C%20Chicago%2C%20Illinois";
  return (
    <WebpageAndAddress
      url={url}
      urlName="Homeslice"
      address="938 W. Webster Ave, Chicago, IL 60614"
    />
  )
}