import React from 'react';
import { Link } from 'react-router-dom';

import { getMainSubPageHeader } from '../MainUtils';

const Registry = () => {
  return (
    <div className="px-4 md:px-6 lg:px-12">
      { getMainSubPageHeader("Registry", "Your presence is present enough!") }

      <div className="md:px-10 pb-12">
        <div className="font-light text-xl">Many of you are traveling across the country or across the globe to celebrate with us in Chicago, so your presence means the world to us and is all we could ever ask for. However, if you do wish to honor us with a gift, a contribution towards our honeymoon fund would be warmly appreciated; please use the link below.
        </div>
        
        <div className="block text-center pt-10">
          <Link 
            to={{ pathname: "https://www.zola.com/registry/claireandgus" }}
            className="px-6 py-3 rounded-md text-xl font-semibold text-primaryTextColor border-3 border-headerFooterBgLight hover:bg-headerFooterBgLight hover:text-white  hover:border-headerFooterBgLight transition ease-out duration-200"
            target="_blank" 
            rel="noopener noreferrer"
            >
              Registry
          </Link>
        </div>

        {/* */}
      </div>
    </div>
  )
}

export default Registry;
