const toLowerCaseReplaceSpacesWithUnderscores = (str) => {
  return str.toLowerCase().replace(/\s/g, '_');
};

const formatDateTimeString = (dateTimeString) => {
  const d = new Date(dateTimeString + " UTC");
  return d.toDateString() + " at " + d.toLocaleTimeString();
}

const concatWords = (words) => {
  if (!words || words.length === 0) {
    return "";
  } else if (words.length === 1) {
    return words[0];
  } else if (words.length === 2) {
    return `${words[0]} and ${words[1]}`
  } else {
    return `${words.slice(0, -1).join(", ")}, and ${words[words.length-1]}`; 
  }
}

const getOrDefault = (value, defaultValue='') => {
  // check if value is null or undefined simultaneously (null == undefined is true)
  return (value == null) ? defaultValue : value;
}

const getCopyright = () => {
  return `C&G Labs © ${new Date().getFullYear()}`;
}

export { toLowerCaseReplaceSpacesWithUnderscores, formatDateTimeString, concatWords, getOrDefault, getCopyright };