import React from 'react';
import { getMainSubPageHeader } from '../MainUtils';
import { HomesliceGoogleMapsLinkAndAddress, CafeBrauerGoogleMapsLinkAndAddress, HotelLincolnWebpageAndAddress, HotelLincolnBookingLink, SonestaESSuitesWebsiteAndAddress, ThompsonChicagoWebsiteAndAddress, WestinWebsiteAndAddress } from './WhenAndWhereData';

const WhenAndWhere = () => {

  const BorderBetweenAccommodations = () => {
    return(
      <hr className="my-2 border border-gray-200" />
    )
  }

  return (
    <div className="px-4 md:px-6 lg:px-12">
      { getMainSubPageHeader("When & Where", "Sweet home Chicago") }

      <div className="px-0 sm:px-4 md:px-8 flex flex-col space-y-8 md:space-y-12 pb-12">

        <div className="grid md:grid-cols-2">
          <div className="font-extralight text-3xl pb-2 md:pb-0">Thursday, 19 May 2022</div>
          <div className="font-light text-xl">
            <p className="font-medium">Welcome Party</p>
            <HomesliceGoogleMapsLinkAndAddress />
            <p>7 - 10pm</p>
            <p className="italic">All guests are invited to join the bride and groom for pizza and drinks to toast the start of the wedding weekend. Grab a jacket - this party is outside!</p>
          </div>
        </div>

        <div className="grid md:grid-cols-2">
          <div className="font-extralight text-3xl pb-2 md:pb-0">Friday, 20 May 2022</div>
          <div className="font-light text-xl">
            <p className="font-medium">Wedding Ceremony and Reception</p>
            <CafeBrauerGoogleMapsLinkAndAddress />
            <p>5pm - Late</p>
          </div>
        </div>

        <div className="grid md:grid-cols-2">
          <div className="font-extralight text-3xl pb-2 md:pb-0">Accommodations</div>
          <div className="font-light text-xl">
            <HotelLincolnWebpageAndAddress />
            <p className="italic">There is a limited <HotelLincolnBookingLink urlName="block of rooms"/> reserved at Hotel Lincoln, which is a 10 minute walk from Café Brauer </p>
            <BorderBetweenAccommodations />
            <SonestaESSuitesWebsiteAndAddress />
            <BorderBetweenAccommodations />
            <ThompsonChicagoWebsiteAndAddress />
            <BorderBetweenAccommodations />
            <WestinWebsiteAndAddress />
          </div>
        </div>

        <div className="grid md:grid-cols-2">
          <div className="font-extralight text-3xl pb-2 md:pb-0">Transportation</div>
          <div className="font-light text-xl">
            <p>Street parking is available at Homeslice.</p>
            <p>Complimentary valet parking is available at Café Brauer.</p>
            <p>All guests are encouraged to walk, take public transportation, or ride share to the welcome party and wedding.</p>
          </div>
        </div>
      
      </div>

    </div>
  )
}

export default WhenAndWhere
